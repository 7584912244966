// const HOST = "https://backend.basacfulkuri.edu.bd";
// const HOST = "https://backend.schooltwo.dcampusweb.com";
const HOST = "https://backend.nababganjdakhilmadrasah.edu.bd";
// const HOST = "http://localhost:8000";
const PREFIX = "api/admin";
const BASE_URL = `${HOST}/${PREFIX}`;

export const LOGIN = `${HOST}/api/auth/login`;
export const AUTH = `${HOST}/api/auth/me`;
export const CHANGE_PASSWORD = `${HOST}/api/auth/password/change`;
export const CHANGE_USERINFO = `${HOST}/api/auth/userinfo/update`;
export const GET_USERINFO = `${HOST}/api/auth/userinfo/get`;
export const RESET_PASSWORD = `${HOST}/api/auth/password/reset`;
export const LOGO = `${HOST}/api/logo`;

export const MENU = `${BASE_URL}/menu`;
export const SCHOOL_INFORMATION = `${BASE_URL}/school`;
export const SCHOOL_INFORMATION_DELETE_IMAGE = `${BASE_URL}/school/delete/image`; // /:secondLogo o banner
export const SPEECH = `${BASE_URL}/speech`;
export const IMPORTANT_LINK = `${BASE_URL}/link`;
export const IMAGE_CREATE = `${BASE_URL}/image`;
export const IMAGE_GET = `${BASE_URL}/image`; /* /:section  */
export const IMAGE_DELETE = `${BASE_URL}/image/delete`;
export const VIDEO = `${BASE_URL}/video`;
export const VIDEO_DELETE = `${BASE_URL}/video/delete`;

export const DETAILS = `${BASE_URL}/details`;
export const INFORMATION = `${BASE_URL}/information`;

export const NOTICE = `${BASE_URL}/notice`;
export const DELETE_NOTICE = `${BASE_URL}/notice/delete`;

export const FACILITY = `${BASE_URL}/facility`;
export const FACILITY_IMAGE_DELETE = `${BASE_URL}/facility/images/delete`;

export const CURRICULAM = `${BASE_URL}/curriculum`;
export const CURRICULAM_IMAGE_DELETE = `${BASE_URL}/curriculum/images/delete`;

export const EVENT_GET = `${BASE_URL}/event/get`;
export const EVENT_CREATE = `${BASE_URL}/event/create`;
export const EVENT_DELETE = `${BASE_URL}/event/delete`;
export const EVENT_IMAGE_DELETE = `${BASE_URL}/event/images/delete`;

export const FORMER_HEADMASTER = `${BASE_URL}/formerheadmaster`;
export const TEACHER = `${BASE_URL}/teacher`;
export const STAFF = `${BASE_URL}/staff`;
export const COMMITTEE = `${BASE_URL}/committee`;

export const CONTACT_MESSAGE = `${BASE_URL}/contact/message`;
export const CONTACT_INFORMATION = `${BASE_URL}/contact/information`;

export const TEST = `${BASE_URL}/home/slider`;
